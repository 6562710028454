import React, { useCallback, useMemo, useRef } from 'react';
import { createSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { VirtualEvent } from '../services/services';
import {
  tryParseDate,
  getEventLivenessState,
  generateSessionId,
  joinExperience
} from '../utils/utils';
import { translation } from '../translation.config';
import AnalyticsEvents from '../utils/analytics';
import { EVENT_LIVENESS_STATE } from '../constants/constants';
import { TGetGameDetails } from '../../common/types/bedev1Types';
import WideTileView from '../../common/components/WideTileView';
import { TComponentType, THoverStyle } from '../../common/types/bedev2Types';
import useEventRsvpStatus from '../hooks/useEventRsvpStatus';
import { buildEventDetailsUrl } from '../../common/utils/browserUtils';
import ExperienceEventTileActionButton from './ExperienceEventTileActionButton';
import ExperienceEventTileThumbnail from './ExperienceEventTileThumbnail';
import ExperienceEventTileFooter from './ExperienceEventTileFooter';
import ExperienceEventTileOverlayPill from './ExperienceEventTileOverlayPill';
import useEventTileImpressionTracker from '../hooks/useEventTileImpressionTracker';

const [SystemFeedback, systemFeedbackService] = createSystemFeedback();

type TExperienceEventsTileProps = {
  eventItem: VirtualEvent;
  universeDetails: TGetGameDetails;
  playabilityStatus: string | undefined;
} & WithTranslationsProps;

const ExperienceEventTile = ({
  eventItem,
  universeDetails,
  playabilityStatus,
  translate
}: TExperienceEventsTileProps): JSX.Element => {
  const tileRef = useRef<HTMLDivElement>(null);

  const isEventLive = useMemo(() => {
    return (
      getEventLivenessState(
        tryParseDate(eventItem.eventTime.startUtc),
        tryParseDate(eventItem.eventTime.endUtc)
      ) === EVENT_LIVENESS_STATE.LIVE
    );
  }, [eventItem.eventTime.startUtc, eventItem.eventTime.endUtc]);

  const trackEventDetailsPageVisit = useCallback(() => {
    AnalyticsEvents.sendEventDetailsPageVisitFromExpDetailsEvent(
      eventItem.id,
      eventItem.universeId
    );
  }, [eventItem.id, eventItem.universeId]);

  useEventTileImpressionTracker(tileRef, eventItem.id, eventItem.universeId);

  const { userHasRsvpd, totalRsvps, handleToggleRsvpClick } = useEventRsvpStatus(
    eventItem,
    systemFeedbackService,
    translate
  );

  const joinEventBtnClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const sessionId = generateSessionId();
      AnalyticsEvents.sendVirtualEventJoinedFromExperienceDetailsPageEvent(
        eventItem.id,
        eventItem.universeId,
        sessionId,
        totalRsvps
      );
      try {
        if (universeDetails.rootPlaceId !== undefined) {
          joinExperience(universeDetails.rootPlaceId, sessionId);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [eventItem.id, eventItem.universeId, universeDetails, totalRsvps]
  );

  const tileThumbnail = (
    <ExperienceEventTileThumbnail
      eventThumbnailId={eventItem.thumbnails?.[0]?.mediaId}
      placeId={universeDetails.rootPlaceId}
      altText={eventItem.title}
    />
  );

  const thumbnailOverlayPill = (
    <ExperienceEventTileOverlayPill
      isEventLive={isEventLive}
      eventStartTimeUtc={eventItem.eventTime.startUtc}
      translate={translate}
    />
  );

  const tileFooter = <ExperienceEventTileFooter eventDescription={eventItem.description} />;

  const actionButton = (
    <ExperienceEventTileActionButton
      isEventLive={isEventLive}
      playabilityStatus={playabilityStatus}
      userHasRsvpd={userHasRsvpd}
      onRsvpButtonClick={handleToggleRsvpClick}
      onJoinEventBtnClicked={joinEventBtnClicked}
      translate={translate}
    />
  );

  const eventReferralUrl = useMemo(() => {
    return buildEventDetailsUrl(eventItem.id);
  }, [eventItem.id]);

  const getShouldShowActionButton = () => {
    // Always show the action button for the Experience Events tile
    return true;
  };

  return (
    <WideTileView
      id={eventItem.id}
      title={eventItem.title}
      linkUrl={eventReferralUrl}
      tileRef={tileRef}
      hoverStyle={THoverStyle.imageOverlay}
      wideTileType={TComponentType.ExperienceEventsTile}
      isHoverEnabled
      isContainedTile
      isOnScreen
      isTileClickEnabled
      onTileClick={trackEventDetailsPageVisit}
      getShouldShowActionButton={getShouldShowActionButton}
      actionButton={actionButton}
      tileThumbnail={tileThumbnail}
      thumbnailOverlayPill={thumbnailOverlayPill}
      tileFooter={tileFooter}
      systemFeedback={<SystemFeedback />}
    />
  );
};

export default withTranslations(ExperienceEventTile, translation);
